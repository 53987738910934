import {mapGetters, mapMutations} from "vuex";
import burgerMenu from "@/components/header/burger-menu/index.vue";

export default {
  name: "main-header",
  components:{
    burgerMenu
  },
  data(){
    return{
      burger: false,
      scroller: false
    }
  },
  watch: {
    '$route'(newVal){
      if(newVal){
        this.burger = false
      }
    },
    burger: {
      immediate: true,
      handler(newVal) {
        if(newVal === true){
          this.fixBody(true)
        }
        else {
          this.fixBody(false)
        }
      }
    },

  },
  created() {

  },
  mounted() {
    window.addEventListener('scroll',()=>{
      window.scrollY > 90 ? this.scroller = true : this.scroller = false
    })
    window.scrollY > 90 ? this.scroller = true : this.scroller = false
  },
  computed:{
    ...mapGetters({
      variables: 'setting/variables'
    }),
    instagram(){
      if(this.variables !== null){
        return this.variables.find(item => item.key === 'instagram')
      }
      else return ''
    },
    facebook(){
      if(this.variables !== null){
        return this.variables.find(item => item.key === 'facebook')
      }
      else return ''
    },
    linkedin(){
      if(this.variables !== null){
        return this.variables.find(item => item.key === 'linkedin')
      }
      else return ''
    },
  },

  methods:{
    ...mapMutations({
      fixBody: 'system/FIX_BODY'
    })
  }
}