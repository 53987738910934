import {$http} from '@/utils/https'
import {
  CHANGE_LOADING_LIST,
  SET_LIST,
  SET_STEPS,
  SET_PARTNERS,
  FEEDBACK_LOADING,
  SET_ABOUT,
  SET_FAQ,
  SET_GALLERY,
  CHANGE_PAGE_SKIP,
  RESET_SKIP,
  SET_CONTACTS, GET_FILTERS_LOADING, SET_FILTERS, SET_LIST_LENGTH
} from '../mutation-types';
import {
  GET_CONTACTS, GET_FILTERS,
  GET_LIST,
  GET_ABOUT,
  GET_FAQ,
  GET_PARTNERS,
  GET_GALLERY,
  GET_STEPS, SEND_FEEDBACK,
} from '../action-types';


const state = {
  list: [],
  listLoading: false,
  contacts: null,
  faq: null,
  filters: [],
  filterLoading: false,
  steps: null,
  partners: null,
  feedbackLoading: false,
  about: null,
  gallery: null,
  limit: 8,
  skip: 1,
};

const getters = {
  contacts: state => state.contacts,
  list: state => state.list,
  listLoading: state => state.listLoading,
  filters: state => state.filters,
  filterLoading: state => state.filterLoading,
  steps: state => state.steps,
  partners: state => state.partners,
  feedbackLoading: state => state.feedbackLoading,
  faq: state => state.faq,
  about: state => state.about,
  gallery: state => state.gallery,
  skip: state => state.skip,
  limit: state => state.limit,

};

const actions = {
  [GET_STEPS]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/steps`);
      commit(SET_STEPS, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_GALLERY]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/gallery?page=${state.skip}&per_page=${state.limit}`);
      commit(SET_GALLERY, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_ABOUT]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/about_us`);
      commit(SET_ABOUT, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_FAQ]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/faqs`);
      commit(SET_FAQ, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_PARTNERS]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/partners`);
      commit(SET_PARTNERS, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [SEND_FEEDBACK]: async ({commit}, payload) => {
    try {
      commit(FEEDBACK_LOADING, true);
      const response = await $http.post(`v1/feedback`, payload);
    } catch (e) {
      throw e;
    }finally {
      commit(FEEDBACK_LOADING, false);
    }
  },
  //------------------------
  [GET_LIST]: async ({commit}) => {
    commit(CHANGE_LOADING_LIST, true);
    try {
      const response = await $http.get(`v1/product?limit=${4}&main_page=true`);

      commit(SET_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_LIST, false);
    }
  },
  [GET_CONTACTS]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/contact`);
      commit(SET_CONTACTS, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_FILTERS]: async ({commit}) => {
    try {
      commit(GET_FILTERS_LOADING, true);
      const response = await $http.get(`v1/category?main_page=true`);
      commit(SET_FILTERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(GET_FILTERS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_STEPS](state, list) {
    state.steps = list;
  },
  [SET_GALLERY](state, list) {
    state.gallery = list;
  },
  [SET_ABOUT](state, list) {
    state.about = list;
  },
  [SET_FAQ](state, list) {
    state.faq = list;
  },
  [SET_PARTNERS](state, list) {
    state.partners = list;
  },
  [SET_LIST](state, list) {
    state.list = list;
  },
  [SET_CONTACTS](state, data) {
    state.contacts = data;
  },
  [CHANGE_LOADING_LIST](state, status) {
    state.listLoading = status;
  },
  [SET_LIST_LENGTH](state, count) {
    state.count = count;
  },
  [SET_FILTERS](state, list) {
    state.filters = list;
  },
  [GET_FILTERS_LOADING](state, status) {
    state.filterLoading = status;
  },
  [FEEDBACK_LOADING](state, status) {
    state.feedbackLoading = status;
  },
  [CHANGE_PAGE_SKIP](state) {
    state.limit += 8;
  },
  [RESET_SKIP](state) {
    state.limit = 8;
    state.skip = 1;
  },

};
export default {
  state,
  getters,
  actions,
  mutations,
};
