import {mapGetters, mapMutations} from "vuex";

export default {
  name: "burger-menu",
  components:{
  },
  data(){
    return{
    }
  },
  created() {

  },
  mounted() {
  },
  computed:{
    ...mapGetters({
      variables: 'setting/variables'
    }),
    instagram(){
      if(this.variables !== null){
        return this.variables.find(item => item.key === 'instagram')
      }
      else return ''
    },
    facebook(){
      if(this.variables !== null){
        return this.variables.find(item => item.key === 'facebook')
      }
      else return ''
    },
    linkedin(){
      if(this.variables !== null){
        return this.variables.find(item => item.key === 'linkedin')
      }
      else return ''
    },
  },

  methods:{
    ...mapMutations({
    })

  }
}