import {$http} from '@/utils/https'
import {
  SET_PRODUCT,
  CHANGE_PRODUCT_LOADING, CHANGE_LOADING_CART,
  RESET_PRODUCT, SET_PRODUCTS, SET_CART,
} from '../mutation-types';

import {
  GET_PRODUCT, ADD_ORDER, GET_CART, ADD_ITEM_TO_CART, GET_PRODUCTS,
} from '../action-types';


const state = {
  product: null,
  productLoading: false,
  cart: null,
  cartLoading: false,
};

const getters = {
  product: state => state.product,
  productLoading: state => state.productLoading,
  cart: state => state.cart,
  cartLoading: state => state.cartLoading,
};

const actions = {
  [GET_PRODUCT]: async ({commit}, payload) => {
    commit(CHANGE_PRODUCT_LOADING, true);
    commit(RESET_PRODUCT);
    try {
      const response = await $http.get(`v1/product/${payload.slug}`);
      commit(SET_PRODUCT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PRODUCT_LOADING, false);
    }
  },
  [GET_CART]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/order/cart?include=products`);
      commit(SET_CART, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [ADD_ITEM_TO_CART]: async ({commit}, {id, attribute_values}) => {
    commit(CHANGE_LOADING_CART, true)
    try {
      const response = await $http.post(`v1/order/cart/products/${id}?include=products`, {attribute_values : attribute_values});
      commit(SET_CART, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_CART, false)
    }
  },
  [ADD_ORDER]: async ({commit}, payload) => {
    try {
      commit(CHANGE_LOADING_CART, true);
      const response = await $http.post(`v1/order/cart/submit?include=products`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_CART, false);
    }
  },

};

const mutations = {
  [SET_PRODUCT](state, obj) {
    state.product = obj;
    state.product.slider.data.unshift({
      id: Math.round(Math.random() * 100),
      link: state.product.image
    })
  },
  [RESET_PRODUCT](state) {
    state.product = null;
  },
  [SET_CART](state, obj) {
    state.cart = obj;
  },
  [CHANGE_PRODUCT_LOADING](state, status) {
    state.productLoading = status;
  },
  [CHANGE_LOADING_CART](state, status) {
    state.cartLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
